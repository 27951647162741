<template>
    <div class="users">
        Users
    </div>
</template>

<script>
// @ is an alias to /src
import UsersService from "../services/usersService";

export default {
  name: 'UsersView',
  data: ()=> {
    return {
        users: [],
        roles: []
    }
  },
  computed: {
  },
  created() {
    this.getUsers();
    this.getRoles();
  },
  methods: {
    getUsers() {
      this.users = UsersService.getUsers()
    },
    getRoles() {
      this.roles = UsersService.getRoles()
    }
  }
}
</script>
<style>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}
</style>
