<template>
    <div class="connections">
        Connections
    </div>
</template> 

<script>
// @ is an alias to /src

export default {
    name: "ConnectionsView",
    components: {},
};
</script>

<style>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}
</style>
