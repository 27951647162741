import axios from "axios";

axios.defaults.baseURL = "https://api.hurodata.com/api/";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";

const ApiService = {
  setHeader() {
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    if (user && user.access_token) {
      axios.defaults.headers.Authorization = `Bearer ${user.access_token}`;
    } else {
      axios.defaults.headers.common.Authorization = "";
    }
  },

  get(resource) {
    this.setHeader();
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;
