<template>
    <div class="project-details">

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600">
                Projects / {{ project.name }}
            </h6>
        </div>

        <!-- Content Row -->
        <div class="row hd-bg-color vh-100" v-cloak>
            <div class="col-lg-5 offset-lg-5 col-sm-12 mx-auto  mb-4 ml-5 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
                            <h1 class="m-0 font-weight-bold">
                                {{ project.name }}
                            </h1>
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item dropdown no-arrow">
                                    <a class="nav-link dropdown-toggle" href="#" id="projectDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in"
                                        aria-labelledby="projectDropdown">
                                        <a class="dropdown-item text-success" href="#" v-on:click="extract()">
                                            <i class="fa fa-solid fa-play"></i>
                                            Extract
                                        </a>
                                        <a class="dropdown-item text-info" href="#" v-on:click="copyProject()" >
                                            <i class="fa fa-regular fa-copy"></i>
                                            Copy project
                                        </a>
                                        <a class="dropdown-item text-warning" href="#" data-dismiss="modal"
                                            data-toggle="modal" data-target="#updateProjectModal">
                                            <i class="fa fa-duotone fa-pen-to-square"></i>
                                            Edit project
                                        </a>
                                        <a class="dropdown-item text-danger" href="#" data-dismiss="modal" data-toggle="modal"
                                            data-target="#removeProjectModal">
                                            <i class="fa-solid fa-trash-can"></i>
                                            Remove project
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <!-- Update project Modal-->
                        <div class="modal fade" id="updateProjectModal" tabindex="-1" role="dialog"
                            aria-labelledby="updateProjectModal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="updateProjectModalLabel">Update project</h5>
                                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form class="">
                                            <div class="input-group">
                                                <div style="min-width:100px;">Name: </div>
                                                <input type="text" class="form-control bg-light border-0 small"
                                                    v-model="project.name" placeholder="Project name" aria-label="Update"
                                                    aria-describedby="basic-addon2" />
                                            </div>
                                            <div class="input-group mt-2">
                                                <span style="min-width:100px;">Date start:</span>
                                                <datepicker v-model="project.dateStart"></datepicker>
                                            </div>
                                            <div class="input-group mt-2">
                                                <span style="min-width:100px;">Date end:</span>
                                                <datepicker v-model="project.dateEnd"></datepicker>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-secondary" type="button" data-dismiss="modal">
                                            Cancel
                                        </button>
                                        <a class="btn btn-warning" data-dismiss="modal" @click="updateProject()">Update</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="removeProjectModal" tabindex="-1" role="dialog"
                            aria-labelledby="removeProjectModal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h6>Are you sure you want to remove project?</h6>
                                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-secondary" type="button" data-dismiss="modal">
                                            Cancel
                                        </button>
                                        <a class="btn btn-primary" data-dismiss="modal"
                                            v-on:click="removeProject()">Remove</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-sm-flex align-items-center mt-2" style="backround-color: #fff">
                            <span class="m-0 font-weight-bold badge badge-light p-2 border">
                                <i class="fa fa-duotone fa-user"></i> {{ project.createdBy.firstName }}
                                {{ project.createdBy.lastName }} at <span style="color:#198ADA;">
                                    {{ project.tenant.name }}</span>
                            </span>
                            <span style="float:left;" class="ml-2 m-0 font-weight-bold  badge badge-ligh p-2 border">
                                <i class="fa fa-light fa-calendar"></i> Created on {{ project.created }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-body">
                        <h1 class="h4">Set up a connection and jobs</h1>
                        <h4 class="h6">
                            Connect your account and add jobs to get started
                        </h4>

                        <ul class="ml-auto list-unstyled">
                            <li class="border mt-3 rounded p-2 no-arrow">
                                <div class="d-sm-flex align-items-center justify-content-between"
                                    style="backround-color: #fff" v-if="!project.connection">
                                    <span class="m-0 font-weight-bold">
                                        Connect your integration
                                    </span>
                                    <a href="#" class="d-none d-sm-inline-block btn btn-primary btn-lg shadow-sm"
                                        data-toggle="modal" data-target="#pickConnectionWizardModal">Connect</a>
                                </div>
                                <div class="d-sm-flex align-items-center justify-content-between" v-if="project.connection"
                                    style="backround-color: #fff">
                                    <span class="m-0 font-weight-bold" style="color:#0469B1">
                                        {{ project.connection.name }}
                                    </span>
                                </div>
                                <div class="d-sm-flex align-items-center justify-content-between mt-2"
                                    v-if="project.connection" style="backround-color: #fff">
                                    <span class="badge badge-danger" v-if="!project.connection.connectionEstablished">
                                        Connection failed
                                    </span>
                                    <span class="badge badge-success" v-else>
                                        Connected
                                    </span>
                                    <div>
                                        <a href="#" class="d-none d-sm-inline-block btn btn-warning shadow-sm mr-2"
                                        style="color:#7B7B7B;" data-toggle="modal" data-target="#updateConnectionWizardModal">Edit connection</a>
                                        <a href="#" class="d-none d-sm-inline-block btn btn-light shadow-sm"
                                            style="color:#7B7B7B;" v-on:click="removeConnection()">Disconnect</a>
                                        <a href="#" class="d-none d-sm-inline-block btn btn-primary shadow-sm ml-1"
                                            v-if="!project.connection.connectionEstablished"
                                            v-on:click="testConnection(project.connectionConfigurationId)">
                                            Try to reconnect</a>
                                    </div>
                                </div>
                            </li>
                            <li class="border mt-3 rounded p-2 no-arrow" style="background-color: #F9F9F9;"
                                v-if="!project.connection || !project.connection.connectionEstablished">
                                <div class="d-sm-flex align-items-center mt-2 justify-content-between"
                                    style="backround-color: #fff">
                                    <span class="m-0 font-weight-bold text-gray-500" v-if="!project.connection">
                                        Add your jobs
                                    </span>
                                </div>
                            </li>
                            <li class="border mt-3 rounded p-2 no-arrow" v-else>
                                <div class="d-sm-flex align-items-center mt-2 justify-content-between"
                                    style="backround-color: #fff">
                                    <span class="m-0 font-weight-bold text-gray-800">
                                    </span>
                                    <a href="#" class="d-none d-sm-inline-block btn btn-primary shadow-sm ml-1"
                                        data-toggle="modal" data-target="#pickJobFromLib"><i class="fa-solid fa-plus"></i>
                                        Add job</a>
                                </div>
                            </li>
                            <li class="border mt-3 rounded p-2 no-arrow" v-if="project.jobs.length > 0">
                                <span class="text-gray-500">Added jobs <span class="text-success" style="font-weight: 700;">
                                        {{ project.jobs.length }} </span></span>
                                <ul class="ml-auto list-unstyled">
                                    <li class="border mt-3 rounded p-2 no-arrow" v-for="(job, index) in project.jobs"
                                        :key="index">

                                        <div class="d-sm-flex align-items-center mt-2 justify-content-between"
                                            style="backround-color: #fff">
                                            <span class="m-0 font-weight-bold text-primary">
                                                <span class="text-gray-800">{{ job.name }} </span>
                                                <span class="ml-3"><i class="fa-solid fa-server"></i> {{ job.allFields }} | </span>
                                                <span class="ml-1"><i class="fa-solid fa-filter"></i> {{ job.allWhereClauses}} | </span>
                                                <span class="ml-1"><i class="fa fa-light fa-network-wired"></i>{{ job.allJoinClauses }} </span>
                                                <span v-if="job.status == 'COMPLETED'" href="#" class="ml-2 d-none d-sm-inline-block shadow-sm badge badge-success p-2">{{ job.status }}</span>
                                                <span v-else-if="job.status == 'NEW'" href="#" class="ml-2 d-none d-sm-inline-block shadow-sm badge badge-info p-2">{{ job.status }}</span>
                                                <span v-else-if="job.status == 'ERROR'" href="#" class="ml-2 d-none d-sm-inline-block shadow-sm badge badge-danger p-2">{{ job.status }}</span>
                                                <span v-else class="ml-2 d-none d-sm-inline-block shadow-sm badge badge-warning p-2">{{ job.status }}</span>
                                            </span>
                                            <div>
                                                <a href="#"
                                                    class="d-none d-sm-inline-block btn btn-sm btn-danger shadow-sm ml-1"
                                                    data-toggle="modal" data-target="#removeJobModal"
                                                    v-on:click="selectJob(job.id)"><i class="fa-solid fa-trash-can"></i>
                                                    Remove job</a>
                                                <router-link :to="{
                                                    name: 'ProjectJobEdit',
                                                    params: { id: project.id, jobId: job.id }
                                                }"
                                                    class="d-none d-sm-inline-block btn btn-sm btn-warning shadow-sm ml-1"><i
                                                        class="fa fa-duotone fa-pen-to-square"></i>
                                                    Edit job
                                                </router-link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>


                        <div class="modal fade" id="removeJobModal" tabindex="-1" role="dialog"
                            aria-labelledby="removeJobModal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h6>Are you sure you want to remove job?</h6>
                                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-secondary" type="button" data-dismiss="modal">
                                            Cancel
                                        </button>
                                        <a class="btn btn-primary" data-dismiss="modal" v-on:click="removeJob()">Remove</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Pick connection or create new one-->
                <div class="modal fade" id="pickConnectionWizardModal" tabindex="-1" role="dialog"
                    aria-labelledby="pickConnectionWizardModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Pick connection or create new one</h5>
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <ul class="ml-auto list-unstyled">
                                    <li class="border mt-3 rounded" v-for="connection in connections" :key="connection.id"
                                        v-on:click="selectConnection(connection.id)">
                                        <div class="d-sm-flex align-items-center mt-2 justify-content-between"
                                            style="backround-color: #fff">
                                            <span class="m-0 font-weight-bold text-gray-800 pl-2">
                                                {{ connection.name }}
                                            </span>
                                            <i v-if="connection.id != selectedConnectionId"
                                                class="fa-sharp fa-regular fa-circle pr-2"></i>
                                            <i v-if="connection.id == selectedConnectionId"
                                                class="fa-solid fa-circle-check pr-2 text-success"></i>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" type="button" data-dismiss="modal">
                                    Cancel
                                </button>
                                <a class="btn btn-primary" data-dismiss="modal" v-on:click="pickConnection()">Pick up</a>
                                <a class="btn btn-primary" data-dismiss="modal" data-toggle="modal"
                                    data-target="#newConnectionWizardModal">Create new connection</a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Create new connection-->
                <div class="modal fade" id="newConnectionWizardModal" tabindex="-1" role="dialog"
                    aria-labelledby="newConnectionWizardModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <img src="../assets/images/sap.svg" />
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5 class="modal-title" id="exampleModalLabel">Setup connection</h5>
                                <form class="user mt-5">
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input type="text" v-model="connectionDto.name"
                                                class="form-control form-control-user" id="name" placeholder="Name">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" v-model="connectionDto.client"
                                                class="form-control form-control-user" id="client" placeholder="Client">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input type="password" v-model="connectionDto.username"
                                                class="form-control form-control-user" id="username" placeholder="Username">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="password" v-model="connectionDto.password"
                                                class="form-control form-control-user" id="password" placeholder="Password">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input type="text" v-model="connectionDto.host"
                                                class="form-control form-control-user" id="host" placeholder="Host">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <div class="row">
                                    <div class="col-md-12 mb-3 mb-sm-0">
                                        <a href="#" class="btn btn-primary" data-dismiss="modal"
                                            v-on:click="createConnection()">
                                            Save
                                        </a>
                                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-success ml-2"
                                            v-if="connectionEstablished != null && connectionEstablished == true">
                                            Test <i class="fa-sharp fa-solid fa-circle-check text-success"></i>
                                        </a>
                                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-danger ml-2"
                                            v-else-if="connectionEstablished != null && connectionEstablished != true">
                                            Test <i class="fa-sharp fa-solid fa-circle-check text-danger"></i>
                                        </a>
                                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-primary ml-2"
                                            v-else>
                                            Test
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Update  connection-->
                <div class="modal fade" id="updateConnectionWizardModal" tabindex="-1" role="dialog"
                    aria-labelledby="updateConnectionWizardModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <img src="../assets/images/sap.svg" />
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5 class="modal-title" id="exampleModalLabel">Setup connection</h5>
                                <form class="user mt-5">
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input type="text" v-model="connectionDto.name"
                                                class="form-control form-control-user" id="name" placeholder="Name">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" v-model="connectionDto.client"
                                                class="form-control form-control-user" id="client" placeholder="Client">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input type="password" v-model="connectionDto.username"
                                                class="form-control form-control-user" id="username" placeholder="Username">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="password" v-model="connectionDto.password"
                                                class="form-control form-control-user" id="password" placeholder="Password">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input type="text" v-model="connectionDto.host"
                                                class="form-control form-control-user" id="host" placeholder="Host">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <div class="row">
                                    <div class="col-md-12 mb-3 mb-sm-0">
                                        <a href="#" class="btn btn-primary" data-dismiss="modal"
                                            v-on:click="updateConnection()">
                                            Save
                                        </a>
                                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-success ml-2"
                                            v-if="connectionEstablished != null && connectionEstablished == true">
                                            Test <i class="fa-sharp fa-solid fa-circle-check text-success"></i>
                                        </a>
                                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-danger ml-2"
                                            v-else-if="connectionEstablished != null && connectionEstablished != true">
                                            Test <i class="fa-sharp fa-solid fa-circle-check text-danger"></i>
                                        </a>
                                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-primary ml-2"
                                            v-else>
                                            Test
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Pick from library or create from scratch-->
                <div class="modal fade" id="pickJobFromLib" tabindex="-1" role="dialog" aria-labelledby="pickJobFromLib"
                    aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content" style="min-width: 550px;">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Select your integration</h5>
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="d-sm-flex align-items-center mt-2 justify-content-between"
                                    style="backround-color: #fff">
                                    <a href="#"><img src="../assets/images/pick_from_library_big.svg" /></a>
                                    <router-link :to="{
                                        name: 'ProjectJobs',
                                        params: { id: projectId }
                                    }" data-dismiss="modal">
                                        <img src="../assets/images/create_from_scratch.svg" data-dismiss="modal" />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService from "../services/projectsService";
import ConnectionsService from "../services/connectionsService";
import { useRoute } from 'vue-router'
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Datepicker from 'vuejs3-datepicker';

export default {
    name: "ProjectDetailsView",
    data: () => {
        return {
            project: {
                createdBy: {},
                tenant: {},
                jobs: []
            },
            connections: [],
            projectId: null,
            selectedConnectionId: null,
            connectionDto: {},
            connectionEstablished: null,
            loading: true,
            selectedJob: null,
            startDate: null,
            endDate: null
        };
    },
    components: {
        LoadingIndicator,
        Datepicker
    },
    created() {
        const route = useRoute();
        this.projectId = route.params.id;

        this.getProject();
        this.getConnections();
    },
    methods: {
        getProject() {
            ProjectsService.getProject(this.projectId).then(
                (project) => {
                    this.project = project;
                    this.startDate = Date.parse(this.project.dateStart);
                    this.endDate = Date.parse(this.project.dateEnd);
                    this.connectionDto = this.project.connection;
                    this.loading = false;
                });
        },
        getConnections() {
            ConnectionsService.getConnections().then((connections) => { this.connections = connections; });
        },
        testConnection(id) {
            ConnectionsService.testConnection(id)
                .then(() => {
                    this.getProject();
                });
        },
        testConnectionDto() {
            ConnectionsService.testConnectionDto(this.connectionDto)
                .then((isOk) => {
                    this.connectionEstablished = isOk;
                });
        },
        selectConnection(id) {
            this.selectedConnectionId = id;
        },
        pickConnection() {
            ProjectsService.addConnection(this.projectId, this.selectedConnectionId)
                .then(() => {
                    this.getProject()
                });
        },
        removeConnection() {
            ProjectsService.removeConnection(this.projectId)
                .then(() => {
                    this.getProject()
                });
        },
        createConnection() {

            this.loading = true;
            this.connectionDto.connectionEstablished = this.connectionEstablished;

            ConnectionsService.createConnection(this.connectionDto).then((id) => {
                ProjectsService.addConnection(this.projectId, id)
                    .then(() => {
                        this.getProject()
                    });
            });
        },
        updateConnection() {

            this.loading = true;
            this.connectionDto.connectionEstablished = this.connectionEstablished;

            ConnectionsService.updateConnection(this.connectionDto.id,this.connectionDto).then(() => {
                this.getProject();
            });
        },
        removeProject() {
            ProjectsService.deleteProject(this.projectId)
                .then(() => {
                    this.$router.push({ name: 'Projects' });
                });
        },
        selectJob(id) {
            this.selectedJob = id;
        },
        removeJob() {
            ProjectsService.removeJob(this.selectedJob)
                .then(() => {
                    this.getProject()
                });
        },
        updateProject() {
            ProjectsService.updateProject(this.projectId, this.project)
                .then(() => {
                    const $toast = useToast();
                    $toast.success('Project is updated');
                    this.getProject();
                });
        },
        extract(){
            ProjectsService.extractProject(this.projectId)
                .then(() => {
                    const $toast = useToast();
                    $toast.success('Project is extracting');
                });
        },
        copyProject(){
            ProjectsService.copyProject(this.projectId)
                .then(() => {
                    const $toast = useToast();
                    $toast.success('Project is copied');
                });
        }
    },
};
</script>

<style>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}

.btn-light>a {
    color: #7B7B7B;
}</style>
