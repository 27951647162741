<template>
    <div class="project">

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
            <h1 class="h3 text-gray-800">Projects</h1>
            <a href="#" class="d-none d-sm-inline-block btn btn-primary btn-sm shadow-sm mr-5" data-toggle="modal"
                data-target="#newProjectModal"><i class="fa-solid fa-plus"></i>
                Create a new project
            </a>
        </div>
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600">
                Add your projects here and start extracting
            </h6>
        </div>

        <!-- Create new project Modal-->
        <div class="modal fade" id="newProjectModal" tabindex="-1" role="dialog" aria-labelledby="newProjectModal"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Create a project</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form class="">
                            <div class="input-group">
                                <input type="text" class="form-control bg-light border-0 small" v-model="projectName" placeholder="Project name"
                                    aria-label="Create" aria-describedby="basic-addon2" />
                            </div>
                            <div class="input-group mt-2">
                                <span style="min-width:100px;">Date start:</span> <datepicker v-model="startDate"></datepicker>
                            </div>
                            <div class="input-group mt-2">
                                <span style="min-width:100px;">Date end:</span> <datepicker v-model="endDate"></datepicker>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">
                            Cancel
                        </button>
                        <a class="btn btn-primary" data-dismiss="modal" @click="createProject()">Create</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content Row -->
        <div class="row hd-bg-color vh-100">
            <div class="col-lg-12 mb-4 ml-5 mt-4 col-md-8 col-xs-4">
                <div class="row mb-6" style="text-align: left">
                    <div class="col-xl-9 col-md-9 col-sm-9">
                        <ul class="nav navbar navbar-left d-flex d-inline-flex shadow p-3 rounded-lg">
                            <li class="nav-item d-inline-flex align-items-center mr-2">
                                <a class="nav-link d-inline-flex" href="#">All projects</a>
                            </li>
                            <li class="nav-item d-inline-flex align-items-center mr-2">
                                <form
                                    class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-200 navbar-search">
                                    <div class="input-group">
                                        <input type="text" class="form-control bg-light border-0 small" v-model="searchTerm"
                                            placeholder="Search for..." aria-label="Search"
                                            aria-describedby="basic-addon2" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button">
                                                <i class="fas fa-search fa-sm"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </li>
                        </ul>
                    </div>

                    <div class="col-xl-3 col-md-3 col-sm-3">
                        <span class="h5 shadow">Sort by:</span>
                        <a href="#" v-on:click="sort()" style="color: #7b7b7b; font-weight: bolder">
                            <span v-html="sortText"></span>
                        </a>
                    </div>
                </div>
                <div class="row mr-5 mt-4">
                    <div class="col-xl-4 col-md-6 mb-3" v-for="project in filteredProjects" :key="project.id">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-sm-flex align-items-center justify-content-between"
                                    style="backround-color: #fff">
                                    <h6 class="m-0 font-weight-bold text-gray-600" v-if="project.connection">
                                        <img src="../assets/images/sap.svg" /> {{ project.connection.name }}
                                    </h6>
                                    <h6 class="m-0 font-weight-bold text-gray-600" v-else>
                                        No connection
                                    </h6>
                                    <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                </div>
                            </div>
                            <div class="card-body">
                                <h1 class="h4">{{ project.name }}</h1>
                                <h4 class="h6">
                                    <i class="fa-solid fa-clipboard-list"></i> {{ project.jobs.length }}
                                </h4>
                            </div>
                            <div class="card-footer">
                                <div class="d-sm-flex align-items-center justify-content-between"
                                    style="backround-color: #fff">
                                    <router-link :to="{
                                        name: 'ProjectDetails',
                                        params: { id: project.id }
                                      }" class="btn btn-outline-primary btn-sm">
                                        See project
                                      </router-link>

                                    <span v-if="project.status == 'COMPLETED'" href="#" class="d-none d-sm-inline-block shadow-sm badge badge-success p-2">{{ project.status }}</span>
                                    <span v-else-if="project.status == 'NEW'" href="#" class="d-none d-sm-inline-block shadow-sm badge badge-info p-2">{{ project.status }}</span>
                                    <span v-else-if="project.status == 'ERROR'" href="#" class="d-none d-sm-inline-block shadow-sm badge badge-danger p-2">{{ project.status }}</span>
                                    <span v-else class="d-none d-sm-inline-block shadow-sm badge badge-warning p-2">{{ project.status }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService from "../services/projectsService";
import ConnectionsService from "../services/connectionsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import Datepicker from 'vuejs3-datepicker';

export default {
    name: "ProjectsView",
    data: () => {
        return {
            projects: [],
            connections: [],
            searchTerm: null,
            filteredProjects: [],
            projectName: null,
            loading: true,
            startDate: null,
            endDate: null,
            url: process.env.VUE_APP_URL, 
            orderBy: false,
            sortText: '<i class="fa-sharp fa-solid fa-arrow-up"></i>',
        };
    },
    components: {
        LoadingIndicator,
        Datepicker
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredProjects = this.projects.filter(x => x.name.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredProjects  = this.projects
            }
        }
    },
    created() {
        this.getProjects();
        this.getConnections();
        
    },
    methods: {
        getProjects() {
            ProjectsService.getProjects(this.orderBy).then(
                (projects) => {
                    this.projects = projects;
                    this.filteredProjects = this.projects;
                    this.loading = false;
                });
        },
        sort(){
            this.orderBy = !this.orderBy;
            this.sortText = this.orderBy ? '<i class="fa-sharp fa-solid fa-arrow-down"></i>' : '<i class="fa-sharp fa-solid fa-arrow-up"></i>';
            this.getProjects();
        },
        getConnections() {
            ConnectionsService.getConnections().then((connections) => { this.connections = connections; });
        },
        createProject(){
            
            let project = 
            { 
                Name: this.projectName,
                DateStart: this.startDate,
                DateEnd: this.endDate
            };

            ProjectsService.createProject(project).then(
            (id) => {
                this.$router.push({ name: 'ProjectDetails', params: { id: id } });
            });
        }
    },
};
</script>

<style>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}
</style>
