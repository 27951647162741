<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion mt-4 ml-2" id="accordionSidebar" style="max-width: 180px;">
      <!-- Sidebar - Brand -->
      <router-link to="/projects" class="sidebar-brand d-flex align-items-center ml-2">
        <div class="sidebar-brand-text">
          <img src="../assets/images/huro_data_left.svg" />
        </div>
      </router-link>

      <!-- Divider -->
      <hr class="sidebar-divider my-0" />

      <!-- Nav Item - Dashboard
      <li class="nav-item">
        <router-link to="/dashboard" class="nav-link"><i class="fa-solid fa-table-columns"></i>
          <span> Dashboard</span>
        </router-link>
      </li> -->

      <li class="nav-item">
        <router-link to="/projects" class="nav-link"><i class="fa-regular fa-calendar-minus"></i>
          <span> Projects</span></router-link>
      </li>

      <li class="nav-item">
        <router-link to="/connections" class="nav-link"><i class="fa-solid fa-network-wired"></i>
          <span> Connections</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="isSuperAdmin">
        <router-link to="/users" class="nav-link"><i class="fa-solid fa-users"></i>
          <span> Users</span>
        </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider d-none d-md-block" />
    </ul>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
          <!-- Sidebar Toggle (Topbar) -->
          <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
          </button>

          <!-- Topbar Navbar -->
          <ul class="navbar-nav ml-auto">
            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <li class="nav-item dropdown no-arrow d-sm-none">
              <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-search fa-fw"></i>
              </a>
              <!-- Dropdown - Messages -->
              <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown">
                <form class="form-inline mr-auto w-100 navbar-search">
                  <div class="input-group">
                    <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                      aria-label="Search" aria-describedby="basic-addon2" />
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button">
                        <i class="fas fa-search fa-sm"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                  Super admin
                </span>
                <img class="img-profile rounded-circle" src="../assets/images/undraw_profile.svg" />
              </a>
              <!-- Dropdown - User Information -->
              <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a class="dropdown-item" href="#">
                  <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </a>
                <a class="dropdown-item" href="#">
                  <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                  Settings
                </a>
                <a class="dropdown-item" href="#">
                  <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                  Activity Log
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <!-- End of Topbar -->

        <!-- Begin Page Content -->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- End of Main Content -->

      <!-- Footer -->
      <!-- <footer class="sticky-footer bg-white">
              <div class="container my-auto">
                  <div class="copyright text-center my-auto">
                      <span>Copyright &copy; Huro Data 2023</span>
                  </div>
              </div>
          </footer> -->
      <!-- End of Footer -->
    </div>
    <!-- End of Content Wrapper -->
  </div>
  <!-- End of Page Wrapper -->

  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>

  <!-- Logout Modal-->
  <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          Select "Logout" below if you are ready to end your current session.
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cancel
          </button>
          <a class="btn btn-primary" data-dismiss="modal" @click="logout()">Logout</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Bootstrap core JavaScript-->
</template>
<script>

// @ is an alias to /src
import { authStore } from './../stores/authStore'

export default {
  name: 'LayoutView',
  computed: {
    isSuperAdmin: () => {
      const authStoreInstance = authStore();
      return authStoreInstance.authUser.user.role == "SuperAdmin"
    }
  },
  methods: {
    logout() {
      const authStoreInstance = authStore();
      authStoreInstance.logout()
      this.$router.push("/login");
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fff;
}

#wrapper #content-wrapper {
  background-color: #fff;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.bg-gradient-primary {
  background-color: #ffffff;
  background-image: none;
  background-size: cover;
}

.sidebar-dark .nav-item .nav-link {
  color: #7b7b7b !important;
}

.sidebar-dark .nav-item .nav-link i {
  color: #7b7b7b;
}

.sidebar .nav-item .nav-link span {
  font-family: "Inter";
  font-style: normal;
}

.nav-item:hover>a>span {
  color: #0469b1;
  font-weight: bold;
}

.nav-item:hover>a>i {
  color: #0469b1 !important;
  font-weight: bold;
}

.container-fluid {
  background-color: #fff;
}

.nav-link:hover {
  color: #7b7b7b !important;
  font-weight: bold;
}

.sidebar-dark .nav-item .nav-link>a>i {
  color: #7b7b7b !important;
}

.router-link-active > i {
  color: #7b7b7b !important;
}

.router-link-active {
  font-weight:900 !important;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 0;
  padding-right: 0;
}

[v-cloak] {
  display: none;
}
</style>
