import { createRouter, createWebHistory } from "vue-router";
import LayoutView from "../views/LayoutView.vue";
import LoginView from "../views/LoginView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import UsersView from "../views/UsersView.vue";
import DashboardView from "../views/DashboardView.vue";
import ConnectionsView from "../views/ConnectionsView.vue";
import ProjectDetailsView from "../views/ProjectDetailsView.vue";
import ProjectJobsView from "../views/ProjectJobsView.vue";
import ProjectJobEditView from "../views/ProjectJobEditView.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "",
    component: LayoutView,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardView,
      },
      {
        path: "/projects",
        name: "Projects",
        component: ProjectsView,
      },
      {
        path: "/projects/:id",
        name: "ProjectDetails",
        component: ProjectDetailsView,
      },
      {
        path: "/projects/:id/jobs",
        name: "ProjectJobs",
        component: ProjectJobsView,
      },
      {
        path: "/projects/:id/jobs/edit/:jobId",
        name: "ProjectJobEdit",
        component: ProjectJobEditView,
      },
      {
        path: "/connections",
        name: "Connections",
        component: ConnectionsView,
      },
      {
        path: "/users",
        name: "Users",
        component: UsersView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    // if (to.path == "/login") {
    //   next("/");
    // }
    next();
  }
});

export default router;
