import ApiService from "./apiService";

class ApplicationError extends Error {
  errorCode;
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const ConnectionsService = {
  async getConnections() {
    try {
      const response = await ApiService.get("/connection-configurations");
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async testConnection(id) {
    try {
      const response = await ApiService.put(`/connection-configurations/test/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async testConnectionDto(dto) {
    try {
      const response = await ApiService.put(`/connection-configurations/test`,dto);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async createConnection(model) {
    try {
      const response = await ApiService.post("/connection-configurations/", model);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async updateConnection(id, connection) {
    try {
      const response = await ApiService.put(`/connection-configurations/${id}`, connection);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async deleteConnection(id) {
    try {
      const response = await ApiService.delete(`/connection-configurations/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async getTables(id) {
    try {
      const response = await ApiService.get(`/connection-configurations/${id}/tables`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
};

export default ConnectionsService;

export { ConnectionsService, ApplicationError };
