import AuthService from "../services/authService";
import { defineStore } from "pinia";

const user = JSON.parse(localStorage.getItem("user") || "{}");
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const authStore = defineStore({
  id: "authStore",
  state: () =>  {
    return {
      authUser: initialState
    }
  },
  actions: {
    login(user) {
      return AuthService.login(user).then(
        (user) => {
          this.loginSuccess(initialState, user);
          return Promise.resolve(user);
        },
        (error) => {
          this.loginFailure(initialState);
          return Promise.reject(error);
        }
      );
    },
    logout() {
      AuthService.logout();
      this.logoutState(initialState);
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    loginSuccess(
      state,
      user
    ) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logoutState(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
});
