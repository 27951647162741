<template>
  <div class="container">

    <!-- Outer Row -->
    <div class="row justify-content-center">

        <div class="col-xl-10 col-lg-12 col-md-9">

            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row" style="min-height:800px;">
                        <div class="col-lg-5">
                          <img src="../assets/images/huro_data_icon.svg"/>
                          <div class="d-flex align-items-center">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h1 text-gray-900 mb-4">Sign in</h1>
                                    <h6 class="h6 text-gray-900 mb-4">Sign in with your company email address</h6>
                                </div>
                                <form class="user">
                                    <div class="form-group">
                                        <input type="email" v-model="user.username" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address...">
                                    </div>
                                    <div class="form-group">
                                        <input type="password" v-model="user.password" class="form-control form-control-user" id="exampleInputPassword" placeholder="Password">
                                    </div>
                                    <a href="#" class="btn btn-primary btn-user btn-block" @click="login(a)">
                                        Sign in
                                    </a>
                                </form>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-7 d-none d-lg-block bg-login-image"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
// @ is an alias to /src
import { authStore } from './../stores/authStore'


export default {
  name: 'LoginView',
  data(){
    return {
        user:{
            username: null,
            password: null
        }
    }
  },
  components: {
    
  },
  methods:{
    login() {
        const authStoreInstance = authStore();
        authStoreInstance.login(this.user).then(() => {
                this.$router.push("projects");
            });
        }
    }
}
</script>

<style scoped>
.bg-login-image {
  background-image: url("../assets/images/login_background.svg");
}
</style>