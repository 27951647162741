import ApiService from "./apiService";

class ApplicationError extends Error {
  errorCode;
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const ProjectsService = {
  async getProjects(orderBy) {
    try {
      const response = await ApiService.get(`/projects/ordered/${orderBy}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async getProject(id) {
    try {
      const response = await ApiService.get(`/projects/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async createProject(model) {
    try {
      const response = await ApiService.post("/projects/", model);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async updateProject(id, project) {
    try {
      const response = await ApiService.put(`/projects/${id}`, project);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async deleteProject(id) {
    try {
      const response = await ApiService.delete(`/projects/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async addConnection(id, connectionId) {
    try {
      const response = await ApiService.put(`/projects/${id}/connection/${connectionId}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async removeConnection(id) {
    try {
      const response = await ApiService.put(`/projects/${id}/remove-connection`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async addJob(id, job) {
    try {
      const response = await ApiService.put(`/projects/${id}/job`, job);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async removeJob(id) {
    try {
      const response = await ApiService.delete(`/projects/remove-job/${id}/`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async updateJob(id, job) {
    try {
      const response = await ApiService.put(`/projects/${id}/edit-job`, job);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async extractProject(id) {
    try {
      const response = await ApiService.post(`/projects/extract/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async copyProject(id) {
    try {
      const response = await ApiService.post(`/projects/copy/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
};

export default ProjectsService;

export { ProjectsService, ApplicationError };
