import ApiService from "./apiService";

class ApplicationError extends Error {
  errorCode;
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UsersService = {
  async getUsers() {
    try {
      const response = await ApiService.get("/users");
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async getRoles() {
    try {
      const response = await ApiService.get("/users/roles");
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async createUser(model) {
    try {
      const response = await ApiService.post("/user/", model);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async updateUser(user) {
    try {
      const response = await ApiService.put(`/user/`, user);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async deleteUser(id) {
    try {
      const response = await ApiService.delete(`/user/${id}`);
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async assignRoleToUser(userId, roleId) {
    try {
      const response = await ApiService.put(
        `/users/user-role/${userId}`,
        roleId
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
  async removeRoleFromUser(userId, roleId) {
    try {
      const response = await ApiService.delete(
        `/users/user-role/${userId}/${roleId}`
      );
      return response.data;
    } catch (error) {
      throw new ApplicationError(
        error.code,
        error.message
      );
    }
  },
};

export default UsersService;

export { UsersService, ApplicationError };
