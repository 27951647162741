<template>
    <div class="project">

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <div class="row hd-bg-color vh-100" v-cloak style="overflow: scroll;">
            <div class="col-lg-6 col-md-6 offset-lg-6 offset-md-6 mx-auto  mb-4 ml-5 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="d-sm-flex align-items-center justify-content-between" style="backround-color: #fff">
                            <h2 class="h6 font-weight-bold">
                                Name:
                            </h2>
                            <input type="text" v-model="job.name" class="form-control bg-light border-0"
                                placeholder="Job name" />
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-body">
                        <form-wizard step-size="xs" color="#04B187" @on-complete="finalize()">
                            <tab-content title="Select tables">
                                <div class="list-inline" v-if="tables">
                                    <div>
                                        <a href="#" class="list-inline-item m-1 mb-2" v-for="table in tables"
                                            :key="table.id" v-on:click="checkTable(table.id, table.isChecked)">
                                            <div v-if="!table.isChecked"
                                                class="d-sm-flex align-items-center justify-content-between p-1"
                                                style="backround-color: #fff">
                                                <span class="m-1 mb-1 font-weight-bold"
                                                    style="color:#a1a1a1; min-width:100px;">
                                                    {{ table.tabname }}
                                                </span>
                                                <i class="fa-solid fa-plus" style="min-width:20px;"></i>
                                            </div>
                                            <div v-else class="d-sm-flex align-items-center justify-content-between p-1"
                                                style="background-color: #EBF7FF;color:#4e73df;">
                                                <span class="m-1 mb-1 font-weight-bold"
                                                    style="color:##4e73df; min-width:100px;">
                                                    {{ table.tabname }}
                                                </span>
                                                <i class="fa-solid fa-check" style="min-width:20px;"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Select fields and priority">
                                <div id="accordion">
                                    <draggable tag="div" v-model="checkedTables" item-key="tabname">
                                        <template #item="{ element }">
                                            <div class="card">
                                            <div class="card-header" :id='"headingOne" + element.tabname'>
                                                <h5 class="mb-0">
                                                    <div class="d-sm-flex align-items-center justify-content-between border m-2 p-2 rounded"
                                                        style="backround-color: #fff">
                                                        <span class="m-0 font-weight-bold">
                                                            <i class="fa-solid fa-grip-vertical"></i> {{ element.tabname }}
                                                        </span>
                                                        <button class="btn btn-sm btn-light" style="min-width:100px;"
                                                            data-toggle="collapse" v-on:click="selectTable(element.id)"
                                                            v-if="checkedFieldsCount(element.id) == 0"
                                                            :data-target='"#acc" + element.id' aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            <span style="color:#0469B1"><i class="fa-solid fa-plus"></i>Add
                                                                fields</span>
                                                        </button>
                                                        <button class="btn btn-sm btn-light" style="min-width:100px;"
                                                            v-on:click="selectTable(element.id)" data-toggle="collapse"
                                                            v-if="checkedFieldsCount(element.id) > 0"
                                                            :data-target='"#acc" + element.id' aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            <span style="color:#0469B1">Edit</span>
                                                        </button>
                                                    </div>
                                                </h5>
                                            </div>
                                            <div :id='"acc" + element.id' class="collapse" aria-labelledby="headingOne"
                                                data-parent="#accordion">
                                                <div class="card-body">
                                                    <div class="d-sm-flex align-items-center justify-content-between border p-2 rounded"
                                                        style="backround-color: #fff">
                                                        <span class="m-0" style="font-size:smaller;">
                                                            Selected fields {{ checkedFieldsCount(element.id) }}
                                                        </span>
                                                        <span class="m-0" style="font-size:smaller;"
                                                            v-if="allCheckedChecker(element.id)">
                                                            <a href="#" class="text-success"
                                                                v-on:click="checkAll(element.id)"><i
                                                                    class="fa-solid fa-circle-check pr-2"></i> Select all
                                                            </a>
                                                        </span>
                                                        <span class="m-0" style="font-size:smaller;" v-else>
                                                            <a href="#" v-on:click="checkAll(element.id)"><i
                                                                    class="fa-sharp fa-regular fa-circle pr-2"
                                                                    style="color:#d1d1d1"></i> Select all </a>
                                                        </span>
                                                    </div>
                                                    <div class="d-sm-flex align-items-center justify-content-between border m-4 p-2 rounded"
                                                        style="backround-color: #fff">
                                                        <div class="input-group">
                                                            <input type="text" v-model="searchTerm"
                                                                class="form-control bg-light border-0"
                                                                placeholder="Search fields" aria-label="Search"
                                                                aria-describedby="basic-addon2" />
                                                        </div>
                                                    </div>
                                                    <div class="overflow-auto" style="height: 300px;">
                                                        <a href="#" class="list-inline-item m-1 mb-2" style="border: 0;"
                                                            v-for="field in filteredFields" :key="field.id"
                                                            v-on:click="checkField(element.id, field.id, field.isChecked)">
                                                            <div v-if="!field.isChecked"
                                                                class="d-sm-flex align-items-center justify-content-between p-1"
                                                                style="backround-color: #fff">
                                                                <i class="fa-sharp fa-regular fa-circle pr-2"
                                                                    style="color:#d1d1d1"></i>
                                                                <span class="m-1 mb-1 font-weight-bold"
                                                                    style="color:#a1a1a1; min-width:100px;">
                                                                    {{ field.fieldname }}
                                                                </span>
                                                            </div>
                                                            <div v-else
                                                                class="d-sm-flex align-items-center justify-content-between p-1">
                                                                <i class="fa-solid fa-circle-check pr-2 text-success"></i>
                                                                <span class="m-1 mb-1 font-weight-bold text-success"
                                                                    style="min-width:100px;">
                                                                    {{ field.fieldname }}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </template>
                                    </draggable>
                                </div>
                            </tab-content>
                            <tab-content title="Finalize">
                                <div id="accordionFinalized">
                                    <div class="card" v-for="checkedTable in checkedTables" :key="checkedTable.id">
                                        <div class="card-header" :id='"headingOne" + checkedTable.tabname'>
                                            <h5 class="mb-0">
                                                <div class="d-sm-flex align-items-center justify-content-between border m-2 p-2 rounded"
                                                    style="backround-color: #fff">
                                                    <span class="m-0 font-weight-bold">
                                                        {{ checkedTable.tabname }}
                                                    </span>
                                                    <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                        data-toggle="collapse" v-on:click="selectTable(checkedTable.id)"
                                                        v-if="checkedTable.id != selectedTableId"
                                                        :data-target='"#acc" + checkedTable.id' aria-expanded="true"
                                                        aria-controls="collapseOne">
                                                        <span style="color:#0469B1"><i
                                                                class="fa fa-duotone fa-chevron-down"></i></span>
                                                    </button>
                                                    <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                        v-on:click="selectTable(checkedTable.id)" data-toggle="collapse"
                                                        v-if="checkedTable.id == selectedTableId"
                                                        :data-target='"#acc" + checkedTable.id' aria-expanded="true"
                                                        aria-controls="collapseOne">
                                                        <span style="color:#0469B1"><i
                                                                class="fa fa-duotone fa-chevron-up"></i></span>
                                                    </button>
                                                </div>
                                            </h5>
                                        </div>
                                        <div :id='"acc" + checkedTable.id' class="collapse" aria-labelledby="headingOne"
                                            data-parent="#accordionFinalized">
                                            <div class="card-body">
                                                <h6 class="h6">Advanced filtering</h6>
                                                <div class="d-sm-flex align-items-center justify-content-between border p-2 mb-2 rounded"
                                                    style="backround-color: #fff">
                                                    <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                        data-toggle="modal" data-target="#filtersModal"
                                                        v-on:click="selectTable(checkedTable.id)">
                                                        <span>Value filter</span>
                                                    </button>
                                                    <button class="btn btn-sm btn-info" style="min-width:10px;"
                                                        data-toggle="modal" data-target="#joinsModal"
                                                        v-on:click="selectTable(checkedTable.id)">
                                                        <span>Join fields</span>
                                                    </button>
                                                </div>

                                                <div class="d-sm-flex align-items-center justify-content-between border p-2 mt-5 rounded"
                                                    style="backround-color: #fff">
                                                    <span class="m-0" style="font-size:smaller;">
                                                        Selected date fields {{ checkedDateFieldsCount(checkedTable.id) }}
                                                    </span>
                                                    <span class="m-0" style="font-size:smaller;"
                                                        v-if="allDateCheckedChecker(checkedTable.id)">
                                                        <a href="#" class="text-success"
                                                            v-on:click="checkDateAll(checkedTable.id)"><i
                                                                class="fa-solid fa-circle-check pr-2"></i> Select all </a>
                                                    </span>
                                                    <span class="m-0" style="font-size:smaller;" v-else>
                                                        <a href="#" v-on:click="checkDateAll(checkedTable.id)"><i
                                                                class="fa-sharp fa-regular fa-circle pr-2"
                                                                style="color:#d1d1d1"></i> Select all </a>
                                                    </span>
                                                </div>
                                                <div class="d-sm-flex align-items-center justify-content-between border m-4 p-2 rounded"
                                                    style="backround-color: #fff">
                                                    <div class="input-group">
                                                        <input type="text" v-model="searchDateFieldTerm"
                                                            class="form-control bg-light border-0"
                                                            placeholder="Search fields" aria-label="Search"
                                                            aria-describedby="basic-addon2" />
                                                    </div>
                                                </div>
                                                <div class="overflow-auto" style="height: 300px;">
                                                    <a href="#" class="list-inline-item m-1 mb-2" style="border: 0;"
                                                        v-for="field in filteredDateFields" :key="field.id"
                                                        v-on:click="checkDateField(checkedTable.id, field.id, field.checkedDate)">
                                                        <div v-if="!field.checkedDate"
                                                            class="d-sm-flex align-items-center justify-content-between p-1"
                                                            style="backround-color: #fff">
                                                            <i class="fa-sharp fa-regular fa-circle pr-2"
                                                                style="color:#d1d1d1"></i>
                                                            <span class="m-1 mb-1 font-weight-bold"
                                                                style="color:#a1a1a1; min-width:100px;">
                                                                {{ field.fieldname }}
                                                            </span>
                                                        </div>
                                                        <div v-else
                                                            class="d-sm-flex align-items-center justify-content-between p-1">
                                                            <i class="fa-solid fa-circle-check pr-2 text-success"></i>
                                                            <span class="m-1 mb-1 font-weight-bold text-success"
                                                                style="min-width:100px;">
                                                                {{ field.fieldname }}
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Pick connection or create new one-->
                                <div class="modal fade" id="filtersModal" tabindex="-1" role="dialog"
                                    aria-labelledby="filtersModal" aria-hidden="true">
                                    <div class="modal-dialog modal-lg" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Value filter</h5>
                                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div v-if="wheres.length > 0">
                                                    <div class="form-row" v-for="(where, index) in selectedWheres" :key="index">
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.field">
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.action">
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.value">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.operator">
                                                        </div>
                                                        <div class="form-group col-md-1">
                                                            <button type="submit" class="btn btn-outline-danger"
                                                                v-on:click="deleteWhere(index)"><i
                                                                    class="fa-solid fa-trash-can"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-3">
                                                            <select id="field" class="form-control" v-model="where.field"
                                                                placeholder="Field">
                                                                <option disabled v-bind:value="null">Field</option>
                                                                <option v-for="field in filteredFields" :key="field.id"
                                                                    :value="field.fieldname"> {{
                                                                        field.fieldname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <select id="action" class="form-control" v-model="where.action">
                                                                <option disabled v-bind:value="null">Action</option>
                                                                <option value="EQUAL">Equal</option>
                                                                <option value="NOT_EQUAL">Not Equal</option>
                                                                <option value="GREATER_THAN">Greater than</option>
                                                                <option value="GREATER_OR_EQUAL_THAN">Greater or Equal than
                                                                </option>
                                                                <option value="LOWER_THAN">Lower than</option>
                                                                <option value="LOWER_OR_EQUAL_THAN">Lower or Equal than
                                                                </option>
                                                                <option value="CONTAINS">Contains</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control" id="whereValue"
                                                                v-model="where.value" placeholder="Value">
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <select id="operator" class="form-control"
                                                                v-model="where.operator">
                                                                <option disabled v-bind:value="null">Operator</option>
                                                                <option value="AND">AND</option>
                                                                <option value="OR">OR</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <a class="btn btn-primary" v-on:click="saveWhereClause()">
                                                    <i class="fa-solid fa-plus" style="min-width:20px;"></i>Add
                                                </a>
                                                <a class="btn btn-success" data-dismiss="modal">Finish</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal fade" id="joinsModal" tabindex="-1" role="dialog"
                                    aria-labelledby="joinsModal" aria-hidden="true">
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Join fields</h5>
                                                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div v-if="joins.length > 0">
                                                    <div class="form-row" v-for="(join, index) in selectedJoins" :key="index">
                                                        <div class="form-group col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.fieldSource">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.action">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.tableDestination">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.fieldDestination">
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.operator">
                                                        </div>
                                                        <div class="form-group col-md-1">
                                                            <button type="submit" class="btn btn-outline-danger"
                                                                v-on:click="deleteJoin(index)"><i
                                                                    class="fa-solid fa-trash-can"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-3">
                                                            <select id="fieldS" class="form-control"
                                                                v-model="join.fieldSource">
                                                                <option disabled v-bind:value="null">Source field</option>
                                                                <option v-for="field in filteredFields" :key="field.id"> {{
                                                                    field.fieldname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="action" class="form-control" v-model="join.action">
                                                                <option disabled v-bind:value="null">Action</option>
                                                                <option value="EQUAL">Equal</option>
                                                                <option value="NOT_EQUAL">Not Equal</option>
                                                                <option value="GREATER_THAN">Greater than</option>
                                                                <option value="GREATER_OR_EQUAL_THAN">Greater or Equal than
                                                                </option>
                                                                <option value="LOWER_THAN">Lower than</option>
                                                                <option value="LOWER_OR_EQUAL_THAN">Lower or Equal than
                                                                </option>
                                                                <option value="CONTAINS">Contains</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="fieldD" class="form-control"
                                                                v-on:change="changeTableFields($event)"
                                                                v-model="join.tableId">
                                                                <option disabled v-bind:value="null">Destination table
                                                                </option>
                                                                <option v-for="table in tables" :key="table.id"
                                                                    :value="table.id">{{ table.tabname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="table" class="form-control"
                                                                v-model="join.fieldDestination">
                                                                <option disabled v-bind:value="null">Destination field
                                                                </option>
                                                                <option v-for="field in joinTableFields" :key="field.id"
                                                                    :value="field.fieldname">{{ field.fieldname }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <select id="operator" class="form-control"
                                                                v-model="join.operator">
                                                                <option disabled v-bind:value="null">Operator</option>
                                                                <option value="AND">AND</option>
                                                                <option value="OR">OR</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-1">
                                                            <button type="submit" class="btn btn-outline-danger"><i
                                                                    class="fa-solid fa-trash-can"></i></button>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <a class="btn btn-primary" v-on:click="saveJoin()">
                                                    <i class="fa-solid fa-plus" style="min-width:20px;"></i>Add
                                                </a>
                                                <a class="btn btn-success" data-dismiss="modal">Finish</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService from "../services/projectsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useRoute } from 'vue-router'
import ConnectionsService from "../services/connectionsService";
import { FormWizard, TabContent } from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import draggable from 'vuedraggable';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
    name: "ProjectJobsView",
    data: () => {
        return {
            currentTabIndex: 0,
            project: {},
            loading: true,
            tables: [],
            fields: [],
            filteredFields: [],
            filteredDateFields: [],
            allChecked: false,
            allDateChecked: false,
            searchTerm: null,
            selectedTableId: null,
            job: {
                name: null,
                tasks: []
            },
            wheres: [],
            joins: [],
            joinTableFields: [],
            where: {
                field: null,
                action: null,
                value: null,
                operator: null,
            },
            join: {
                id: null,
                fieldSource: null,
                fieldDestination: null,
                action: 0,
                operator: null,
                tableId: null,
                tableDestination: null
            },
            checkedTables: []
        };
    },
    components: {
        LoadingIndicator,
        FormWizard,
        TabContent,
        draggable
    },
    computed: {
        selectedWheres() {
            return this.wheres.filter(x=>x.selectedTableId == this.selectedTableId);
        },
        selectedJoins() {
            return this.joins.filter(x=>x.selectedTableId == this.selectedTableId);
        }
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredFields = this.tables.find(x => x.id == this.selectedTableId)
                    .fields.filter(x => x.fieldname.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredFields = this.tables.find(x => x.id == this.selectedTableId).fields;
            }
        },
        searchDateFieldTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId)
                    .fields.filter(x => x.fieldname.toLowerCase().indexOf(this?.searchDateFieldTerm?.toLowerCase()) > -1);
            } else {
                this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId).fields;
            }
        }
    },
    created() {
        const route = useRoute();
        this.projectId = route.params.id;

        this.getProject();
    },
    methods: {
        selectTable(tableId) {
            this.selectedTableId = tableId;
            this.filteredFields = this.tables.find(x => x.id == this.selectedTableId).fields.sort((a) => (a.isChecked) ? -1 : 1).sort((a,b) => (a.fieldname < b.fieldname && a.isChecked) ? -1 : 1);
            this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId).fields.sort((a) => (a.checkedDate) ? -1 : 1).sort((a,b) => (a.fieldname < b.fieldname && a.checkedDate) ? -1 : 1);
            this.searchTerm = null;
            this.searchDateFieldTerm = null;
        },
        allCheckedChecker(tableId) {
            return !this.tables.find(x => x.id == tableId).fields.filter(x => !x.isChecked).length > 0;
        },
        allDateCheckedChecker(tableId) {
            return !this.tables.find(x => x.id == tableId).fields.filter(x => !x.checkedDate).length > 0;
        },
        checkedFieldsCount(tableId) {
            return this.tables.find(x => x.id == tableId).fields.filter(x => x.isChecked).length;
        },
        checkedDateFieldsCount(tableId) {
            return this.tables.find(x => x.id == tableId).fields.filter(x => x.checkedDate).length;
        },
        checkTable(id, isChecked) {
            this.tables.find(x => x.id == id).isChecked = !isChecked;
            this.checkedTables =  this.tables.filter(x => x.isChecked);
        },
        checkField(tableId, fieldId, isChecked) {
            this.tables.find(x => x.id == tableId).fields.find(x => x.id == fieldId).isChecked = !isChecked;
        },
        checkDateField(tableId, fieldId, isChecked) {
            this.tables.find(x => x.id == tableId).fields.find(x => x.id == fieldId).checkedDate = !isChecked;
        },
        checkAll(tableId) {
            this.tables.find(x => x.id == tableId).fields.forEach((item) => { item.isChecked = !this.allChecked; })
            this.allChecked = !this.allChecked;
        },
        checkDateAll(tableId) {
            this.tables.find(x => x.id == tableId).fields.forEach((item) => { item.checkedDate = !this.allDateChecked; })
            this.allDateChecked = !this.allDateChecked;
        },
        getProject() {
            ProjectsService.getProject(this.projectId).then(
                (project) => {
                    this.project = project;
                    this.getTables();
                });
        },
        getTables() {
            this.loading = true;
            ConnectionsService.getTables(this.project.connectionConfigurationId).then(
                (tables) => {
                    tables.forEach((item) => {
                        item.isChecked = false;

                        item.fields.forEach((f) => {
                            f.isChecked = false;
                            f.checkedDate = false;
                        })

                        this.tables.push(item);
                    })

                    this.loading = false;
                });
        },
        changeTableFields(event) {
            this.joinTableFields = this.tables.find(x => x.id == event.target.value).fields;
        },
        saveWhereClause() {
            let where = {
                selectedTableId: this.selectedTableId,
                where: this.where
            };

            this.wheres.push(where);
            this.where = {
                field: null,
                action: 0,
                value: null,
                operator: null,
            };
        },
        saveJoin() {

            let tableSelected = this.tables.find(x => x.id == this.join.tableId);
            
            let join = {
                selectedTableId: this.selectedTableId,
                join: this.join
            };

            join.join.destTable = tableSelected.tabname;

            this.joins.push(join);
            this.join = {
                id: null,
                fieldSource: null,
                fieldDestination: null,
                action: 0,
                operator: null,
                tableId: null,
                tableDestination: null
            };
        },
        deleteWhere(index) {
            this.wheres.splice(index, 1);
        },
        deleteJoin(index) {
            this.joins.splice(index, 1)
        },
        finalize() {

            if(this.job.name == null){
                const $toast = useToast();
                $toast.error('You need to specify project name!');
                return ;
            }
            this.job.tasks = [];

            this.checkedTables.forEach((i, index) => {

                var checkedFields = [];
                var checkedDates = [];

                i.fields.forEach(f => {
                    if (f.isChecked) {
                        checkedFields.push(f.fieldname);
                    }

                    if (f.checkedDate) {
                        checkedDates.push(f.fieldname);
                    }
                })

                let task = {
                    name: i.tabname,
                    dateFields: checkedDates,
                    fields: checkedFields,
                    whereClauses: this.getWheres(this.wheres.filter(x=>x.selectedTableId == i.id)),
                    joinClauses: this.getJoins(this.joins.filter(x=>x.selectedTableId == i.id)),
                    order: index
                }

                this.job.tasks.push(task);
            });

            ProjectsService.addJob(this.projectId, this.job).then(
            () => {
                this.$router.push({ name: 'ProjectDetails', params: { id: this.projectId } });
            });
        },
        getWheres(selectedWheres){
            let wheres = []

            selectedWheres.forEach((w) => wheres.push(w.where));

            return wheres;
        },
        getJoins(selectedJoins){
            let joins = []

            selectedJoins.forEach((j) => joins.push(j.join));

            return joins;
        }
    }
};
</script>

<style>
.card {
    text-align: left;
}

.hd-bg-color {
    background-color: #f1f1f1;
}

.card-header {
    background-color: #fff;
    border-bottom: 0;
}

.nav-item>a {
    color: #7b7b7b;
    font-weight: bolder;
}

.badge-info {
    color: #0469b1;
    background-color: #c6e7ff;
}

.list-inline-item {
    border: 1px #d1d1d1 solid;
}</style>
